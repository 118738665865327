import React, {useState, KeyboardEvent} from 'react'
import {Link, useHistory} from 'react-router-dom'
import {GlobalHotKeys} from 'react-hotkeys'
import {useRouteMatch} from 'react-router-dom'
import clsx from 'clsx'
import {
  Icon,
  List,
  ListItem,
  ListItemIcon,
  ListSubheader,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
  Collapse,
  Badge,
} from '@material-ui/core'
import {UnreadBadge} from '.'
import {AddWorkspaceIcon, AddPersonalWorkspaceIcon} from './icons'
import {makeStyles, Theme} from '@material-ui/core/styles'
import {
  useGetWorkspacesQuery,
  useGetAllNotesSubscription,
  Permissions_Enum,
} from '../generated/types'
import {NewWorkspaceDialog} from './index'
import keymap from '../utils/keymap'
import routes from '../routes'
import {useNavState} from '../utils/nav-context'
import {KeyboardArrowDown, KeyboardArrowUp} from '@material-ui/icons'
import Sidebar from './sidebar'
import {useSendbirdContext} from '../lib/messages-context'
import getNoteProps from '../utils/note-props'

const UserSidebar = () => {
  const history = useHistory()
  const {isSidebarOpen} = useNavState()
  const {loading, error, data} = useGetWorkspacesQuery()
  const {data: notesData} = useGetAllNotesSubscription()
  const [isNewWorkspaceDialogOpen, setNewWorkspaceDialogOpen] = useState(false)
  const [isWorkspacePublic, setIsWorkspacePublic] = useState(false)

  const {unreadCountPerClient} = useSendbirdContext()
  const totalClientUnread = Object.values(unreadCountPerClient).reduce((a, b) => a + b, 0)

  const workspaceMatch: any = useRouteMatch(routes.user.workspace.path)?.params
  const activeWorkspaceId = parseInt(workspaceMatch?.workspaceId || '', 10)
  const isFeedActive = !!useRouteMatch(routes.user.root)?.isExact
  const isClientActive = !!useRouteMatch(routes.user.clients)

  const numNewNotes = () => {
    var unreadCount = notesData?.notes.reduce(function (ctr, note) {
      return ctr + (getNoteProps(note).isNewForUser(note) ? 1 : 0)
    }, 0)
    return unreadCount
  }

  const openWorkspace = (i: number, e?: KeyboardEvent) => {
    if (data && i < data.workspaces.length) {
      history.push(routes.user.workspace.createUrl(data.workspaces[i].id))
    }
    e?.preventDefault()
  }

  const hotkeyHandlers = {
    FEED: (e: any) => history.push('/'),
    NEW_WORKSPACE: (e: any) => {
      setNewWorkspaceDialogOpen(true)
      e.preventDefault()
    },
    WORKSPACE_1: (e: any) => openWorkspace(0, e),
    WORKSPACE_2: (e: any) => openWorkspace(1, e),
    WORKSPACE_3: (e: any) => openWorkspace(2, e),
    WORKSPACE_4: (e: any) => openWorkspace(3, e),
    WORKSPACE_5: (e: any) => openWorkspace(4, e),
    WORKSPACE_6: (e: any) => openWorkspace(5, e),
    WORKSPACE_7: (e: any) => openWorkspace(6, e),
    WORKSPACE_8: (e: any) => openWorkspace(7, e),
    WORKSPACE_9: (e: any) => openWorkspace(8, e),
  }

  if (error) return <div>ERROR: {error.message}</div>
  return (
    <>
      {data && <GlobalHotKeys keyMap={keymap} handlers={hotkeyHandlers} />}
      <Sidebar>
        <SidebarButton
          to={routes.user.root.createUrl()}
          icon="list_alt"
          name="Global Feed"
          unread={numNewNotes()}
          active={isFeedActive}
          isSidebarOpen={isSidebarOpen}
        />
        <SidebarButton
          to={routes.user.clients.createUrl()}
          icon="people_alt"
          name="Clients"
          unread={totalClientUnread}
          active={isClientActive}
          isSidebarOpen={isSidebarOpen}
        />
        <SectionHeader
          dataTest="new-shared-workspace"
          name="Teams"
          open={isSidebarOpen}
          icon={<AddWorkspaceIcon />}
          onClick={() => {
            setIsWorkspacePublic(true)
            setNewWorkspaceDialogOpen(true)
          }}
        />
        {loading
          ? '...'
          : data?.workspaces
              .filter((w) => w.permission === Permissions_Enum.TeamVisible)
              .map((workspace, i) => (
                <SidebarButton
                  key={i}
                  id={workspace.id}
                  active={workspace.id === activeWorkspaceId}
                  to={routes.user.workspace.createUrl(workspace.id)}
                  icon={workspace.icon}
                  name={workspace.name}
                  isSidebarOpen={isSidebarOpen}
                  showSubMenu={isSidebarOpen}
                />
              ))}
        <SectionHeader
          dataTest="new-personal-workspace"
          name="Personal"
          open={isSidebarOpen}
          icon={<AddPersonalWorkspaceIcon />}
          onClick={() => {
            setIsWorkspacePublic(false)
            setNewWorkspaceDialogOpen(true)
          }}
        />
        {loading
          ? '...'
          : data?.workspaces
              .filter((w) => w.permission === Permissions_Enum.Private)
              .map((workspace, i) => (
                <SidebarButton
                  key={i}
                  active={workspace.id === activeWorkspaceId}
                  to={routes.user.workspace.createUrl(workspace.id)}
                  icon={workspace.icon}
                  name={workspace.name}
                  isSidebarOpen={isSidebarOpen}
                />
              ))}
      </Sidebar>

      <NewWorkspaceDialog
        isOpen={isNewWorkspaceDialogOpen}
        isPublic={isWorkspacePublic}
        setIsPublic={setIsWorkspacePublic}
        onClose={() => setNewWorkspaceDialogOpen(false)}
      />
    </>
  )
}

const renderIcon = (isSidebarOpen: boolean, icon: string, unread?: number) => {
  if (isSidebarOpen) {
    return <Icon>{icon}</Icon>
  } else {
    return (
      <Badge color="error" badgeContent={unread}>
        <Icon>{icon}</Icon>
      </Badge>
    )
  }
}

type SBButtonProps = {
  to: string
  name: string
  icon: string
  id?: number
  bottom?: boolean
  active?: boolean
  unread?: number
  isSidebarOpen?: boolean
  showSubMenu?: boolean
}
const SidebarButton = ({
  name,
  icon,
  to,
  bottom,
  id,
  active,
  unread = 0,
  isSidebarOpen = false,
  showSubMenu = false,
}: SBButtonProps) => {
  const classes = useStyles({open: false, active: !!active})
  const handleClick = () => {
    setOpenMenu(!openMenu)
  }
  const props = {component: Link, to}
  const [openMenu, setOpenMenu] = React.useState(false)
  const nestedTypography = {style: {fontSize: '15px', paddingLeft: '42px'}}
  return (
    <>
      <ListItem
        button
        onClick={handleClick}
        {...props}
        selected={active}
        className={clsx(
          classes.sidebarButton,
          {[classes.buttonBottom]: bottom},
          {[classes.activeSidebarButton]: active},
        )}
      >
        <ListItemIcon className={classes.icon}>
          {renderIcon(isSidebarOpen, icon, unread)}
        </ListItemIcon>
        <ListItemText primary={name} primaryTypographyProps={{noWrap: true}} />
        {isSidebarOpen && <UnreadBadge count={unread} />}
        {showSubMenu && <>{openMenu ? <KeyboardArrowUp /> : <KeyboardArrowDown />}</>}
      </ListItem>
      {showSubMenu && (
        <Collapse in={openMenu} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <ListItem
              button
              className={classes.nested}
              {...{component: Link, to: routes.user.workspaceFeed.createUrl(id)}}
            >
              <ListItemText primary="Feed" primaryTypographyProps={nestedTypography} />
            </ListItem>
            <ListItem
              button
              className={classes.nested}
              {...{component: Link, to: routes.user.workspaceModels.createUrl(id)}}
            >
              <ListItemText primary="Models" primaryTypographyProps={nestedTypography} />
            </ListItem>
            <ListItem
              button
              className={classes.nested}
              {...{component: Link, to: routes.user.workspaceNews.createUrl(id)}}
            >
              <ListItemText primary="News" primaryTypographyProps={nestedTypography} />
            </ListItem>
          </List>
        </Collapse>
      )}
    </>
  )
}

type SHProps = {
  name: string
  onClick: () => any
  open: boolean
  icon: React.ReactNode
  dataTest: string
}
const SectionHeader = ({name, onClick, open, dataTest, icon}: SHProps) => {
  const classes = useStyles({open, active: false})
  return (
    <ListItem>
      <ListSubheader component="div" disableSticky className={classes.subheader}>
        {name}
      </ListSubheader>
      <ListItemSecondaryAction>
        <IconButton
          data-test={dataTest}
          className={classes.subheaderAction}
          edge="end"
          aria-label="comments"
          onClick={onClick}
          size="small"
        >
          {icon}
        </IconButton>
      </ListItemSecondaryAction>
    </ListItem>
  )
}

const useStyles = makeStyles<Theme, {open: boolean; active: boolean}>((theme) => ({
  buttonBottom: {
    justifySelf: 'flex-end',
  },
  sidebarButton: {
    transition: 'transform 250ms',
  },
  icon: {
    color: (props) => (props.active ? theme.palette.text.primary : theme.palette.action.active),
  },
  subheader: {
    marginBottom: -theme.spacing(1.5),
    textTransform: 'uppercase',
    letterSpacing: '1.5px',
    fontSize: '10px',
    color: theme.palette.text.hint,
    padding: 0,
    marginTop: -theme.spacing(1.5),
    transition: 'opacity 250ms',
    opacity: (props) => (props.open ? 1 : 0),
  },
  activeSidebarButton: {
    background: theme.palette.action.selected,
    color: theme.palette.text.primary,
    fontWeight: 600,
    borderLeft: `3px solid ${theme.brand.active.main}`,
    paddingLeft: '13px',
  },
  subheaderAction: {
    color: theme.palette.text.hint,
  },
  nested: {
    paddingLeft: theme.spacing(1),
  },
}))

export default UserSidebar
