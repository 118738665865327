import {
  Permissions_Enum,
  Note_Types_Enum,
  Notes,
  PublicNotePreviewFragment,
  FrontendNoteFragment,
  NotePreviewFragment,
} from '../generated/types'

import SvgIcon from '@material-ui/core/SvgIcon'

import {
  PersonalNoteIcon,
  TeamNoteIcon,
  PublicNoteIcon,
  LinkIcon,
  FileIcon,
  NoteIcon,
} from '../components/icons'

export enum ContentType {
  Personal = 'Personal',
  Team = 'Team',
  Client = 'Client',
  ClientLink = 'Link',
  ClientFileNote = 'FileNote',
}

type PermissionDict = {[key in Permissions_Enum]: string}
type ContentNodeDict = {[key in ContentType]: typeof SvgIcon}
type NoteTypeDict = {[key in Note_Types_Enum]: string}
type NoteTypeNodeDict = {[key in Note_Types_Enum]: typeof SvgIcon}
type PermissionContentDict = {[key in Permissions_Enum]: ContentType}

export const PERMISSION_CONTENT_TYPE: PermissionContentDict = {
  [Permissions_Enum.Private]: ContentType.Personal,
  [Permissions_Enum.TeamVisible]: ContentType.Team,
  [Permissions_Enum.TeamEdit]: ContentType.Team,
  [Permissions_Enum.OrgVisible]: ContentType.Team,
  [Permissions_Enum.Public]: ContentType.Client,
}

export const NOTE_ICONS_BY_PERMISSION: PermissionDict = {
  [Permissions_Enum.Private]: 'person',
  [Permissions_Enum.TeamVisible]: 'group',
  [Permissions_Enum.TeamEdit]: 'group_add',
  [Permissions_Enum.OrgVisible]: 'group_add',
  [Permissions_Enum.Public]: 'public',
}

export const NOTE_PREVIEW_ICONS: NoteTypeNodeDict = {
  [Note_Types_Enum.Note]: NoteIcon,
  [Note_Types_Enum.File]: FileIcon,
  [Note_Types_Enum.Link]: LinkIcon,
}

export const NOTE_TYPE_NAMES: NoteTypeDict = {
  [Note_Types_Enum.Note]: 'Note',
  [Note_Types_Enum.File]: 'File',
  [Note_Types_Enum.Link]: 'Link',
}

export const CONTENT_ICONS: ContentNodeDict = {
  [ContentType.Personal]: PersonalNoteIcon,
  [ContentType.Team]: TeamNoteIcon,
  [ContentType.Client]: PublicNoteIcon,
  [ContentType.ClientFileNote]: NoteIcon,
  [ContentType.ClientLink]: LinkIcon,
}

export const PERMISSION_NAMES: PermissionDict = {
  [Permissions_Enum.Private]: 'Personal',
  [Permissions_Enum.TeamVisible]: 'Team',
  [Permissions_Enum.TeamEdit]: 'Team',
  [Permissions_Enum.OrgVisible]: 'Company',
  [Permissions_Enum.Public]: 'Public',
}

type GenericNote =
  | Notes
  | FrontendNoteFragment
  | PublicNotePreviewFragment
  | NotePreviewFragment
  | null
  | undefined

export const getRealPermission = (note: GenericNote) => {
  if (!note) return Permissions_Enum.Private
  if (note.hasOwnProperty('permission')) {
    if (!!(note as Notes).published_note_id) return Permissions_Enum.Public
    return (note as Notes).permission || Permissions_Enum.Private
  } else {
    return Permissions_Enum.Public
  }
}

export const isUnread = (note: GenericNote) => {
  // A Note is read, iff there's a row in the notes_users_notifications table with a
  // read_at timestamp greater than the last_modified attribute on the note object.
  // It's also read when it's a personal note (no one else could've modified the note)
  if (getRealPermission(note) !== Permissions_Enum.Private) {
    const notification = note?.notes_users_notifications
    if (notification) {
      const user_read_time = new Date(notification.read_at)
      const note_updated_time = new Date(note?.last_modified)
      return note_updated_time > user_read_time
    } else {
      // There's no row in the database, it's the first time the user's seen the note, hence unread.
      return true
    }
  }
  return false
}

export const isNewForUser = (note: GenericNote) => {
  // A Note is new, iff there's a no row in the notes_users_notifications table for the user_id + note_id.
  // It's also not new when it's a personal note
  if (getRealPermission(note) !== Permissions_Enum.Private) {
    if (note?.notes_users_notifications) {
      return false
    } else {
      return true
    }
  }
  return false
}

const getNoteProps = (note: GenericNote) => {
  const realPermission = getRealPermission(note)
  const contentType = PERMISSION_CONTENT_TYPE[realPermission]
  const noteType = note?.type || Note_Types_Enum.Note
  const unread = isUnread(note)

  return {
    realPermission,
    contentType,
    noteType,
    icon: NOTE_ICONS_BY_PERMISSION[Permissions_Enum.Private],
    previewIcon: NOTE_PREVIEW_ICONS[noteType],
    header: `${PERMISSION_NAMES[realPermission]} ${NOTE_TYPE_NAMES[noteType]}`,
    unread: unread,
    isNewForUser: isNewForUser,
  }
}

export default getNoteProps
