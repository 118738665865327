import React, {useEffect} from 'react'
import {Typography} from '@material-ui/core'
import {useNavDispatch} from '../../utils/nav-context'

const ChartTrades = () => {
  const navDispatch = useNavDispatch()

  useEffect(() => {
    navDispatch({isChartSubpage: true})
  }, [navDispatch])

  return (
    <>
      <Typography variant="h2">Fundamentals</Typography>
      <div
        style={{
          display: 'flex',
          width: '100%',
          margin: 'auto',
          height: '100%',
          alignItems: 'center',
        }}
      >
        <iframe
          style={{border: 'unset'}}
          title="figmaembed"
          width="100%"
          height="100%"
          src="https://www.figma.com/embed?embed_host=outline&url=https://www.figma.com/proto/JArJOFJ9VrBXsWHsLIMg5f/Light-Design-System?node-id=2384%3A5817%26scaling%3Dmin-zoom%26hide-ui=1"
        ></iframe>
      </div>
    </>
  )
}

export default ChartTrades
