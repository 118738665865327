import React, {useState, ChangeEvent, useEffect, Fragment, useRef} from 'react'
import {GlobalHotKeys} from 'react-hotkeys'
import {makeStyles, fade} from '@material-ui/core/styles'
import {Typography, Icon, Grid, InputBase} from '@material-ui/core'
import {format} from 'date-fns'
import Autocomplete from '@material-ui/lab/Autocomplete'

import {useNavDispatch} from '../../utils/nav-context'
import {AlogliaNoteType, userIndex} from '../../api/algolia'
import keymap from '../../utils/keymap'
import {isNoteVisibleForUser} from '../../utils/permissions'
import {useUserState} from '../../utils/user-context'

function Search() {
  const classes = useStyles()
  const [inputValue, setInputValue] = useState('')
  const inputRef = useRef<HTMLInputElement>(null)
  const [options, setOptions] = useState<AlogliaNoteType[]>([])
  const [selectedOption, setSelectedOption] = useState<AlogliaNoteType | null>(null)
  const navDispatch = useNavDispatch()
  const {user} = useUserState()

  const hotkeyHandlers = {
    HIGHLIGHT_SEARCH: (event: any) => {
      if (inputRef && inputRef.current) {
        inputRef.current.focus()
        event.preventDefault()
      }
    },
  }

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setInputValue(event.target.value)
  }

  const handleResultSelected = async (event: ChangeEvent<{}>, value: AlogliaNoteType | null) => {
    if (value) navDispatch({openNote: {noteId: value.noteId}})
    setInputValue('doo')
    setSelectedOption(null)
    setOptions([])
    inputRef?.current?.blur()
  }

  useEffect(() => {
    let isActive = true
    userIndex
      .search(inputValue, {
        filters: 'type:note',
      })
      .then(({hits}: any) => {
        if (isActive) setOptions(hits)
      })
    return () => {
      isActive = false
    }
  }, [inputValue])

  // TODO: In the future this filtering should happen on Aloglia using:
  // https://www.algolia.com/doc/guides/security/api-keys/how-to/how-to-restrict-the-search-to-a-subset-of-records-belonging-to-a-specific-user/
  const handleFilterOptions = (options: AlogliaNoteType[]) => {
    return options.filter((option) => isNoteVisibleForUser(option, user))
  }

  return (
    <Fragment>
      <GlobalHotKeys keyMap={keymap} handlers={hotkeyHandlers} />
      <Autocomplete
        getOptionLabel={(option: AlogliaNoteType) => option.title}
        value={selectedOption}
        classes={{
          popper: classes.suggestions,
        }}
        onChange={handleResultSelected}
        filterOptions={handleFilterOptions}
        options={options}
        autoComplete
        includeInputInList
        openOnFocus
        className={classes.search}
        renderInput={(params) => (
          <Fragment>
            <Icon className={classes.searchIcon}>search</Icon>
            <InputBase
              ref={params.InputProps.ref}
              inputProps={params.inputProps}
              inputRef={inputRef}
              placeholder="Search…"
              onChange={handleChange}
              value={inputValue}
              classes={{
                root: classes.inputRoot,
                input: classes.inputInput,
                focused: classes.inputFocused,
              }}
            />
          </Fragment>
        )}
        renderOption={(option) => (
          <SearchOptionDisplay
            title={option.title}
            subtitle={option.ownerDisplayName}
            annotation={format(new Date(option.createdAt), 'MMM d')}
          />
        )}
      />
    </Fragment>
  )
}

type SODProps = {
  title: string
  subtitle: string
  annotation: string
}
const SearchOptionDisplay = ({annotation, subtitle, title}: SODProps) => (
  <Grid container alignItems="center">
    <Grid item xs>
      <Typography variant="body2" color="textPrimary">
        {title}
      </Typography>
      <Typography variant="body2" color="textSecondary">
        {subtitle}
      </Typography>
    </Grid>
    <Grid item>
      <Typography variant="body2" color="textSecondary">
        {annotation}
      </Typography>
    </Grid>
  </Grid>
)

const useStyles = makeStyles((theme) => ({
  suggestions: {
    minWidth: 450,
    marginRight: theme.spacing(1),
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    marginRight: theme.spacing(1),
    marginLeft: theme.spacing(1),
  },
  searchIcon: {
    margin: theme.spacing(0, 1),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    opacity: 0.8,
    color: theme.palette.text.primary,
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputRoot: {
    color: theme.palette.text.primary,
    width: 120,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: 300,
    }),
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    borderRadius: 30,
    // vertical padding + font size from searchIcon
    backgroundColor: fade(theme.brand.primary.main, 0.125),
    '&:hover': {
      backgroundColor: fade(theme.brand.primary.main, 0.25),
    },
    paddingLeft: `calc(1em + ${theme.spacing(3)}px)`,
    width: '100%',
    display: 'block',
  },
  inputFocused: {
    borderRadius: 4,
    backgroundColor: fade(theme.brand.primary.main, 0),
    width: 450,
  },
}))

export default Search
