import React, {useState, useRef} from 'react'
import GenericChartComponent from 'react-financial-charts/lib/GenericChartComponent'
import {ContentType} from '../../../utils/note-props'
import {
  Notes,
  PublicNotePreviewFragment,
  Note_Types_Enum,
  IohlcData,
} from '../../../generated/types'
import {OpenNote} from '../../../utils/nav-context'
import NoteBubble from './note-icon'
import {FileIcon, NoteIcon, LinkIcon} from '../../icons'
import {Menu, MenuItem, ListSubheader, ListItemIcon} from '@material-ui/core'
import {useTheme} from '@material-ui/core/styles'

type TimelineProps = {
  index: number
  notes: (Notes | PublicNotePreviewFragment)[]
  openNotes?: number[]
  contentType: ContentType
  onNoteClick?: (openNote: OpenNote) => any
  onCreateContent?: (time: Date, type: Note_Types_Enum, context: ContentType) => any
}

type Point = [number, number]

type HoverProps = {
  mouseXY: Point
  currentItem: IohlcData
}

const Timeline = ({
  index,
  onCreateContent,
  onNoteClick,
  openNotes = [],
  contentType,
  notes,
}: TimelineProps) => {
  const theme = useTheme()
  const color = theme.brand.context[contentType.toLowerCase()]
  const menuAnchor = useRef<SVGGElement>(null)
  const [createContentDate, setCreateContentDate] = useState<Date | null>(null)
  const [hoverX, setHoverX] = useState<number | null>(null)
  const [menuX, setMenuX] = useState<number | null>(null)
  const strokeWidth = 2
  const tolerance = 8
  const strokeOpacity = 1
  const y = 32 + index * 32

  const isHover = ({mouseXY}: HoverProps) => {
    const mouseY: number = mouseXY[1]
    const hover = mouseY >= y - tolerance && mouseY <= y + tolerance
    setHoverX(hover ? mouseXY[0] : null)
    return hover
  }

  const onHover = (moreProps: any) => {}

  const handleMenuClose = () => {
    setCreateContentDate(null)
    setMenuX(null)
  }
  const handleMenuOpen = ({currentItem, mouseXY}: HoverProps) => {
    if (!onCreateContent) return
    setMenuX(mouseXY[0])
    currentItem?.date && setCreateContentDate(new Date(currentItem.date))
  }

  const handleCreateContent = (type: Note_Types_Enum, content: ContentType) => {
    if (!createContentDate || !onCreateContent) return
    onCreateContent(createContentDate, type, content)
    handleMenuClose()
  }

  const renderMenu = () => {
    if (!createContentDate || !onCreateContent) return
    return (
      <Menu
        anchorEl={menuAnchor.current}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        getContentAnchorEl={null}
        open={!!createContentDate}
        onClose={handleMenuClose}
      >
        <ListSubheader style={{lineHeight: '20px'}}>Add...</ListSubheader>
        <MenuItem onClick={() => handleCreateContent(Note_Types_Enum.Note, contentType)}>
          <ListItemIcon>
            <NoteIcon />
          </ListItemIcon>
          Note
        </MenuItem>
        <MenuItem onClick={() => handleCreateContent(Note_Types_Enum.File, contentType)}>
          <ListItemIcon>
            <FileIcon />
          </ListItemIcon>
          File
        </MenuItem>
        <MenuItem onClick={() => handleCreateContent(Note_Types_Enum.Link, contentType)}>
          <ListItemIcon>
            <LinkIcon />
          </ListItemIcon>
          Link
        </MenuItem>
      </Menu>
    )
  }

  const renderSVG = () => {
    return (
      <line
        x1={0}
        y1={y}
        x2="100%"
        y2={y}
        stroke={color}
        strokeWidth={strokeOpacity * strokeWidth}
        strokeOpacity={strokeOpacity}
      />
    )
  }

  const renderPlus = () => {
    if (!hoverX && !menuX) return
    const x = menuX || hoverX || 0
    return (
      <g ref={menuAnchor}>
        <circle r="8" cx={x - 8} cy={y} fill={color} />
        <path
          transform={`translate(${x - 12},${y - 4})`}
          d="M5 0H3V3H0V5H3V8H5V5H8V3H5V0Z"
          fill="white"
        />
      </g>
    )
  }

  return (
    <>
      <GenericChartComponent
        isHover={isHover}
        svgDraw={renderSVG}
        onHover={onHover}
        onClickWhenHover={handleMenuOpen}
        onUnHover={() => setHoverX(null)}
        drawOn={['mousemove', 'pan', 'drag']}
      />
      {!!onCreateContent && renderPlus()}
      {notes &&
        notes.map((note) => (
          <NoteBubble
            y={y}
            key={note.id}
            note={note}
            contentType={contentType}
            onClick={onNoteClick}
            active={openNotes?.includes(note.id)}
          />
        ))}
      {!!onCreateContent && renderMenu()}
    </>
  )
}

export default Timeline
