import React, {useState} from 'react'
import clsx from 'clsx'
import {
  Drawer,
  Icon,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
  useTheme,
} from '@material-ui/core'
import {OutlineIcon} from './icons'
import {makeStyles, Theme} from '@material-ui/core/styles'
import {AccountSettingsDialog} from './index'
import {useNavState, useNavDispatch} from '../utils/nav-context'
import {UsernameAvatar} from '../utils/initials'
import {useUserState} from '../utils/user-context'

type SidebarProps = {
  subtitle?: string
}

const Sidebar: React.FC<SidebarProps> = ({children, subtitle}) => {
  const {isSidebarOpen} = useNavState()
  const dispatch = useNavDispatch()
  const {user} = useUserState()
  const [isAccountSettingsDialogOpen, setAccountSettingsDialogOpen] = useState(false)
  const theme = useTheme()
  const classes = useStyles({open: isSidebarOpen, active: false})

  const handleDrawerClose = () => {
    dispatch({setIsSidebarOpen: false})
  }

  const handleDrawerOpen = () => {
    dispatch({setIsSidebarOpen: true})
  }

  const renderHeader = () => {
    if (!isSidebarOpen)
      return (
        <ListItem>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            className={clsx(classes.menuButton, {
              [classes.hide]: isSidebarOpen,
            })}
          >
            <OutlineIcon style={{color: theme.brand.active.logo}} />
          </IconButton>
        </ListItem>
      )

    return (
      <ListItem className={classes.toolbar}>
        <ListItemIcon className={classes.icon}>
          <OutlineIcon style={{color: theme.brand.active.logo}} />
        </ListItemIcon>
        <ListItemText
          classes={{
            primary: classes.outline,
            secondary: classes.alpha,
          }}
          primary="Outline"
          secondary={subtitle}
          secondaryTypographyProps={{variant: 'overline'}}
          primaryTypographyProps={{noWrap: true}}
        />
        <ListItemSecondaryAction className={classes.subheaderAction}>
          <IconButton
            aria-label="comments"
            color="default"
            data-test="close-drawer"
            edge="end"
            onClick={handleDrawerClose}
            size="small"
          >
            <Icon>chevron_left</Icon>
          </IconButton>
        </ListItemSecondaryAction>
      </ListItem>
    )
  }
  return (
    <>
      <Drawer
        variant="permanent"
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: isSidebarOpen,
          [classes.drawerClose]: !isSidebarOpen,
        })}
        classes={{
          paper: clsx({
            [classes.drawerOpen]: isSidebarOpen,
            [classes.drawerClose]: !isSidebarOpen,
          }),
        }}
      >
        <List style={{flex: 1, paddingTop: 0}}>
          {renderHeader()}
          {children}
        </List>

        <div className={classes.bottomSection}>
          <ListItem
            button
            className={clsx({
              [classes.bottomButtonClosed]: !isSidebarOpen,
              [classes.bottomButtonOpen]: isSidebarOpen,
            })}
            id="openSupportLink"
          >
            <ListItemIcon className={classes.icon}>
              <Icon>help_outline</Icon>
            </ListItemIcon>
            <ListItemText
              primary="Support"
              primaryTypographyProps={{noWrap: true}}
              style={{paddingLeft: '30px'}}
            />
          </ListItem>

          <ListItem
            disableGutters
            button
            id="settings"
            onClick={() => setAccountSettingsDialogOpen(true)}
          >
            <ListItemIcon
              className={classes.icon}
              style={{paddingLeft: '13px', paddingRight: '13px'}}
            >
              <UsernameAvatar name={user?.full_name || ''} />
            </ListItemIcon>
            <ListItemText primaryTypographyProps={{noWrap: true}}>{user?.full_name}</ListItemText>
          </ListItem>
        </div>
      </Drawer>
      <AccountSettingsDialog
        isOpen={isAccountSettingsDialogOpen}
        onClose={() => setAccountSettingsDialogOpen(false)}
      />
    </>
  )
}

const useStyles = makeStyles<Theme, {open: boolean; active: boolean}>((theme) => ({
  bottomButtonClosed: {
    justifySelf: 'flex-end',
  },
  bottomButtonOpen: {
    background:
      theme.palette.type === 'dark'
        ? // TODO: use theme for support button styling
          'linear-gradient(180deg, hsl(223, 24%, 28%) 0%, hsl(223, 23%, 25%) 100%)'
        : 'linear-gradient(91.74deg, hsl(223, 60%, 97%) 0.31%, hsl(223, 39%, 93%) 100%)',
    boxShadow: '0px 1px 4px rgba(0, 0, 0, 0.1)',
    borderRadius: '4px',
    margin: '13px',
    width: 'unset',
  },
  bottomSection: {
    backgroundColor: theme.palette.background.default,
  },
  icon: {
    color: (props) => (props.active ? theme.palette.common.white : theme.palette.action.active),
  },
  drawer: {
    width: theme.shape.drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
  },
  drawerOpen: {
    width: theme.shape.drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: theme.shape.drawerClosedWidth,
  },
  subheader: {
    marginBottom: -theme.spacing(1.5),
    textTransform: 'uppercase',
    letterSpacing: '1.5px',
    fontSize: '10px',
    color: theme.palette.text.hint,
    padding: 0,
    marginTop: -theme.spacing(1.5),
    transition: 'opacity .2s linear',
    opacity: (props) => (props.open ? 1 : 0),
  },
  subheaderAction: {
    color: theme.palette.text.hint,
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 2),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  outline: {
    letterSpacing: '3px',
    textTransform: 'uppercase',
  },
  alpha: {
    lineHeight: '10px',
    fontSize: '10px',
    marginTop: -2,
  },
}))

export default Sidebar
