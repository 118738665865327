import React from 'react'
import ToolbarChat from './toolbar-chat'
import ClientSearch from './client-search'
import Toolbar from './toolbar'
import ClientChartToolbar from '../chart/client-chart-toolbar'
import {useNavState} from '../../utils/nav-context'

const ClientToolbar = () => {
  const {activeSymbol, isChartSubpage} = useNavState()
  return (
    <Toolbar>
      {activeSymbol && !isChartSubpage && <ClientChartToolbar symbol={activeSymbol} />}
      <div style={{flexGrow: 1}} />
      <ClientSearch />
      <ToolbarChat />
    </Toolbar>
  )
}

export default ClientToolbar
