import React from 'react'
import {Link} from 'react-router-dom'
import {useRouteMatch} from 'react-router-dom'
import {
  Icon,
  ListItem,
  ListItemIcon,
  ListSubheader,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
  Collapse,
  List,
} from '@material-ui/core'
import {makeStyles, Theme} from '@material-ui/core/styles'
import {
  useGetAllPublicNotesSubscription,
  useGetClientChartsSubscription,
  useDeleteClientChartMutation,
  ClientChartFragment,
} from '../generated/types'
import ClientChartPicker from './client-chart-picker'
import routes from '../routes'
import {useNavState} from '../utils/nav-context'
import Sidebar from './sidebar'
import {UnreadBadge} from '.'
import {difference} from 'underscore'
import {useUserState} from '../utils/user-context'
import {KeyboardArrowDown, KeyboardArrowUp} from '@material-ui/icons'

import clsx from 'clsx'

const ClientSidebar = () => {
  const {isSidebarOpen} = useNavState()

  const {data, error} = useGetAllPublicNotesSubscription()
  const {data: chartData} = useGetClientChartsSubscription()
  const {user} = useUserState()

  const [deleteChart] = useDeleteClientChartMutation()

  const classes = useStyles({open: isSidebarOpen, active: false})

  const handleDeleteChart = (symbol: string) => {
    deleteChart({
      variables: {
        userId: user?.id || '',
        symbol,
      },
    })
  }

  const unread = data?.public_notes.map((p) => p.threads[0]?.read_at).filter((r) => !r).length || 0

  const charts = (data?.public_notes
    .map((p) => p.chart_symbol)
    // Filter out undefined and duplicates
    .filter((c, i, arr) => !!c && arr.indexOf(c) === i) || []) as string[]

  const clientCharts = difference(
    chartData?.client_charts.map((c: ClientChartFragment) => c.symbol) || [],
    charts,
  ) as string[]

  const chartMatch: any = useRouteMatch(routes.client.chart.path)?.params
  const activeChartSymbol = chartMatch?.symbol || ''
  const isRootActive = !!useRouteMatch(routes.client.root)?.isExact
  const isNotesActive = !!useRouteMatch(routes.client.note)

  if (error) return <div>ERROR: {error.message}</div>
  return (
    <Sidebar subtitle="Client Portal">
      <ListItem button component={Link} to={routes.user.root.createUrl()} selected={isRootActive}>
        <ListItemIcon className={classes.icon}>
          <Icon>dashboard</Icon>
        </ListItemIcon>
        <ListItemText primary="Dashboard" primaryTypographyProps={{noWrap: true}} />
      </ListItem>
      <ListItem
        button
        component={Link}
        to={routes.client.feed.createUrl()}
        selected={isNotesActive}
      >
        <ListItemIcon className={classes.icon}>
          <Icon>list_alt</Icon>
        </ListItemIcon>
        <ListItemText primary="Global Feed" primaryTypographyProps={{noWrap: true}} />
        <UnreadBadge count={unread} />
      </ListItem>
      <ListItem>
        <ListSubheader component="div" disableSticky className={classes.subheader}>
          Markets
        </ListSubheader>
      </ListItem>
      {charts.map((c) => (
        <SidebarButton
          key={c}
          chartSymbol={c}
          to={routes.client.chart.createUrl(c)}
          active={c === activeChartSymbol}
          showSubMenu={isSidebarOpen}
        />
      ))}
      {clientCharts.map((c) => (
        <ListItem
          button
          key={c}
          component={Link}
          to={routes.client.chart.createUrl(c)}
          selected={c === activeChartSymbol}
        >
          <ListItemIcon className={classes.icon}>
            <Icon>insert_chart_outlined</Icon>
          </ListItemIcon>
          <ListItemText primary={c} primaryTypographyProps={{noWrap: true}} />
          <ListItemSecondaryAction>
            <IconButton
              edge="end"
              onClick={() => handleDeleteChart(c)}
              size="small"
              className={classes.cancelButton}
            >
              <Icon>cancel</Icon>
            </IconButton>
          </ListItemSecondaryAction>
        </ListItem>
      ))}
      <ClientChartPicker />
    </Sidebar>
  )
}

type SBButtonProps = {
  chartSymbol: string
  to: string
  active: boolean
  showSubMenu: boolean
}

const SidebarButton = ({chartSymbol, to, active, showSubMenu}: SBButtonProps) => {
  const [openMenu, setOpenMenu] = React.useState(false)
  const classes = useStyles({open: false, active: !!active})
  const nestedTypography = {style: {fontSize: '15px', paddingLeft: '60px'}}
  const props = {component: Link, to}
  const handleClick = () => {
    setOpenMenu(!openMenu)
  }

  return (
    <>
      <ListItem
        button
        onClick={handleClick}
        {...props}
        selected={active}
        className={clsx(classes.sidebarButton, {[classes.activeSidebarButton]: active})}
        to={routes.client.chart.createUrl(chartSymbol)}
      >
        <ListItemIcon className={classes.icon}>
          <Icon>insert_chart_outlined</Icon>
        </ListItemIcon>
        <ListItemText primary={chartSymbol} primaryTypographyProps={{noWrap: true}} />
        {showSubMenu && <>{openMenu ? <KeyboardArrowUp /> : <KeyboardArrowDown />}</>}
      </ListItem>
      {showSubMenu && (
        <Collapse in={openMenu} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <ListItem
              button
              className={classes.nested}
              {...{component: Link, to: routes.client.chartFeed.createUrl(chartSymbol)}}
            >
              <ListItemText primary="Feed" primaryTypographyProps={nestedTypography} />
            </ListItem>
            <ListItem
              button
              className={classes.nested}
              {...{component: Link, to: routes.client.chartFundamentals.createUrl(chartSymbol)}}
            >
              <ListItemText primary="Fundamentals" primaryTypographyProps={nestedTypography} />
            </ListItem>
          </List>
        </Collapse>
      )}
    </>
  )
}

const useStyles = makeStyles<Theme, {open: boolean; active: boolean}>((theme) => ({
  buttonBottom: {
    justifySelf: 'flex-end',
  },
  icon: {
    color: (props) => (props.active ? theme.palette.text.primary : theme.palette.action.active),
  },
  sidebarButton: {
    transition: 'transform 250ms',
  },
  activeSidebarButton: {
    background: theme.palette.action.selected,
    color: theme.palette.text.primary,
    fontWeight: 600,
    borderLeft: `3px solid ${theme.brand.active.main}`,
    paddingLeft: '13px',
  },
  cancelButton: {
    opacity: 0.3,
    '&:hover': {opacity: 1},
  },
  drawer: {
    width: theme.shape.drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
  },
  drawerOpen: {
    width: theme.shape.drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: theme.shape.drawerClosedWidth,
  },
  subheader: {
    marginBottom: -theme.spacing(1.5),
    textTransform: 'uppercase',
    letterSpacing: '1.5px',
    fontSize: '10px',
    color: theme.palette.text.hint,
    padding: 0,
    marginTop: -theme.spacing(1.5),
    transition: 'opacity .2s linear',
    opacity: (props) => (props.open ? 1 : 0),
  },
  subheaderAction: {
    color: theme.palette.text.hint,
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 2),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  outline: {
    letterSpacing: '3px',
    textTransform: 'uppercase',
  },
  alpha: {
    lineHeight: '10px',
    fontSize: '10px',
    marginTop: -2,
  },
  nested: {
    paddingLeft: theme.spacing(1),
  },
}))

export default ClientSidebar
