import React, {useState} from 'react'
import {Typography, Menu, MenuItem, Icon} from '@material-ui/core'
import {Permissions_Enum} from '../../generated/types'
import {NestedMenu} from '..'
import {NOTE_ICONS_BY_PERMISSION} from '../../utils/note-props'
import {useNoteState} from './note-context'
import PublishNoteDialog from '../dialogs/publish-note-dialog'

const NOTE_SHARING_OPTIONS: [Permissions_Enum, string][] = [
  [Permissions_Enum.Private, 'Personal Note'],
  [Permissions_Enum.TeamVisible, 'Share with Team'],
  [Permissions_Enum.OrgVisible, 'Share with Org'],
]

type MenuProps = {
  anchor: null | HTMLElement
  onClose: () => void
}

const NoteMenu = ({anchor, onClose}: MenuProps) => {
  const [isDialogOpen, setIsDialogOpen] = useState(false)
  const {permission, isPublished, unpublishNote, setPermission, deleteNote} = useNoteState()

  const handleDialogClose = () => {
    setIsDialogOpen(false)
    onClose()
  }

  return (
    <>
      <PublishNoteDialog isOpen={isDialogOpen} onClose={handleDialogClose} />

      <Menu
        id="context-menu"
        anchorEl={anchor}
        open={!!anchor}
        onClose={onClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        getContentAnchorEl={null}
      >
        <NestedMenu label="Share Note" parentMenuOpen={!!anchor} key="layout">
          {NOTE_SHARING_OPTIONS.map((option: [Permissions_Enum, string]) => (
            <MenuItem
              key={option[1]}
              selected={permission === option[0]}
              onClick={() => {
                setPermission(option[0])
                onClose()
              }}
            >
              <Icon fontSize="small" style={{marginRight: 5}}>
                {NOTE_ICONS_BY_PERMISSION[option[0]]}
              </Icon>{' '}
              {option[1]}
            </MenuItem>
          ))}
        </NestedMenu>

        <MenuItem key="settings" onClick={() => setIsDialogOpen(true)}>
          {isPublished ? 'Publish changes...' : 'Publish note...'}
        </MenuItem>
        {isPublished ? (
          <MenuItem key="unpublish" onClick={unpublishNote}>
            Unpublish note
          </MenuItem>
        ) : null}
        <MenuItem key="delete">
          <Typography color="error" onClick={deleteNote}>
            Delete note
          </Typography>
        </MenuItem>
      </Menu>
    </>
  )
}

export default NoteMenu
