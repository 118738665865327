import React from 'react'
import {useHistory} from 'react-router-dom'
import {useChannel} from '../../lib/channel-context'

import {useGetPublicNoteForChartSubscription} from '../../generated/types'
import routes from '../../routes'
import Chart from './chart'
import {OpenNote} from '../../utils/nav-context'
import {Loading} from '..'
import {ContentType} from '../../utils/note-props'

type UCNProps = {
  clientId: string
  publicNoteId: number
}
const UserClientNoteChart = ({clientId, publicNoteId}: UCNProps) => {
  const history = useHistory()
  const {data, loading, error} = useGetPublicNoteForChartSubscription({
    variables: {clientId, publicNoteId},
  })

  const channelId = data?.public_note?.message_threads[0]?.channel_id
  const symbol = data?.public_note?.chart_symbol

  const {sendDataMessage} = useChannel(channelId)

  if (error) throw error
  if (loading || !symbol) return <Loading />

  const handleNoteClick = (openNote: OpenNote) => {
    history.push(routes.user.clientsClientNoteChart.createUrl(clientId, openNote.noteId))
  }

  const handleChartClick = (time: Date, symbol: string, value: number) => {
    sendDataMessage({time, value})
  }

  const notes = data?.public_note?.related_notes

  return (
    <Chart
      contentTypes={[ContentType.ClientFileNote, ContentType.ClientLink]}
      symbol={symbol}
      notes={notes || []}
      groupByFor={'client'}
      openNotes={[publicNoteId]}
      channelId={channelId}
      onNoteClick={handleNoteClick}
      onChartClick={handleChartClick}
    />
  )
}

export default UserClientNoteChart
