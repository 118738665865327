import React from 'react'

const ClientDashboardViewer = () => {
  return (
    <div style={{display: 'flex'}}>
      <img src={'https://outline-app.s3.amazonaws.com/Client_Dashboard_V6.svg'} alt="Logo" />
    </div>
  )
}

export default ClientDashboardViewer
