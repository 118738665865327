import React, {MouseEvent} from 'react'
import {isoParse} from 'd3-time-format'
import {Tooltip, IconButton, Typography, Badge} from '@material-ui/core'
import {makeStyles, Theme, fade} from '@material-ui/core'
import {useTheme} from '@material-ui/core/styles'

import {ClosestMatchAnnotate} from './closest-match-annotation'
import {Notes, PublicNotePreviewFragment} from '../../../generated/types'
import getNoteProps, {ContentType} from '../../../utils/note-props'
import {OpenNote} from '../../../utils/nav-context'
import clsx from 'clsx'

type NAProps = {
  xAccessor?: any
  xScale?: any
  y: number
  contentType: ContentType
  active?: boolean
  datum?: object
  note: Notes | PublicNotePreviewFragment
  color: string
  onClick?: (openNote: OpenNote) => void
}

const NoteAnnotation = ({
  xAccessor,
  y,
  contentType,
  xScale,
  datum,
  onClick,
  note,
  active,
}: NAProps) => {
  const x = xScale(xAccessor(datum))
  const {previewIcon: PreviewIcon, unread} = getNoteProps(note)
  const theme = useTheme()
  const color = theme.brand.context[contentType.toLowerCase()]
  const classes = useStyles({color})

  const handleClick = (event: MouseEvent<SVGElement>) => {
    const {x, y} = (event.target as SVGElement).getBoundingClientRect()
    onClick && onClick({noteId: note.id, location: {x, y}})
  }

  const renderIcon = () => {
    if (unread) {
      return (
        <>
          <Badge
            color="secondary"
            variant="dot"
            style={{
              left: 22,
              bottom: 10,
            }}
          />
          <PreviewIcon />
        </>
      )
    } else {
      return <PreviewIcon />
    }
  }

  return (
    <g
      className={clsx(classes.noteIcon, {
        [classes.noteIconNew]: false,
        [classes.noteIconActive]: active,
      })}
      onClick={handleClick}
    >
      <foreignObject x={x - 24} y={y - 24} width="48" height="48">
        <Tooltip
          title={
            <>
              <Typography className={classes.author}>{note.user.full_name}</Typography>
              <Typography className={classes.title}>{note.title}</Typography>
            </>
          }
          classes={{tooltip: classes.tooltip, arrow: classes.arrow}}
          placement="bottom"
          arrow
        >
          <IconButton size="small" disableRipple>
            {renderIcon()}
          </IconButton>
        </Tooltip>
      </foreignObject>
    </g>
  )
}

type ChartNoteIconProps = {
  note: Notes | PublicNotePreviewFragment
  contentType: ContentType
  onClick?: (openNote: OpenNote) => void
  active?: boolean
  y: number
}

const ChartNoteIcon = ({contentType, onClick, y, note, active}: ChartNoteIconProps) => {
  const when = isoParse(note.time)
  if (!when) return null

  return (
    <ClosestMatchAnnotate
      with={NoteAnnotation}
      when={when}
      usingProps={{
        note,
        contentType,
        active,
        y,
        onClick,
      }}
    />
  )
}

const useStyles = makeStyles<Theme, {color: string}>((theme) => ({
  '@keyframes newNote': {
    '0%': {
      boxShadow: (props) => `0px 0px 1px 0px ${fade(props.color, 1)}`,
    },
    '100%': {
      boxShadow: (props) => `0px 0px 1px 8px ${fade(props.color, 0.1)}`,
    },
  },

  '.MuiBadge-dot': {
    height: `10px`,
  },

  noteIcon: {
    cursor: 'pointer',
    '& .MuiIconButton-root': {
      margin: 8,
      transition: 'box-shadow 0.2s',
      backgroundColor: (props) => props.color,
      color: theme.palette.background.default,
      padding: 1,
    },
    '&:hover .MuiIconButton-root': {
      boxShadow: (props) => `0px 0px 1px 8px ${fade(props.color, 0.3)}`,
    },
  },
  noteIconNew: {
    '& .MuiIconButton-root': {
      animation: `$newNote 1000ms infinite`,
    },
  },
  noteIconActive: {
    '& .MuiIconButton-root': {
      boxShadow: (props) => `0px 0px 1px 8px ${fade(props.color, 0.3)}`,
    },
  },
  title: {
    color: theme.palette.text.primary,
    marginTop: 0,
  },
  author: {
    color: theme.palette.text.secondary,
    marginTop: 4,
    textTransform: 'uppercase',
    fontSize: theme.typography.fontSize * 0.9,
  },
  arrow: {
    color: (props) => props.color,
  },
  tooltip: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: (props) => `0 4px 6px -4px rgba(0,0,0,0.50), inset 0 10px 0 -5px ${props.color}`,
    fontSize: 11,
  },
}))

export default ChartNoteIcon
