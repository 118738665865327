import React, {useEffect, useState} from 'react'
import {
  CardHeader,
  List,
  ListItem,
  Divider,
  makeStyles,
  useTheme,
  Typography,
} from '@material-ui/core'
import {useParams} from 'react-router-dom'

import {Loading} from '../../components'
import ThreePanes from '../../components/layouts/three-panes'
import TwoPanes from '../../components/layouts/two-panes'

import NoteListItem from '../../components/notes/note-list-item'
import {useGetAllPublicNotesSubscription} from '../../generated/types'
import {NoteViewer} from '.'
import {useNavDispatch} from '../../utils/nav-context'

const ChartFeed = () => {
  const classes = useStyles()
  const theme = useTheme()
  const {symbol: chartSymbol} = useParams()
  const [selectedNoteId, setSelectedNoteId] = useState()
  const {loading, data, error} = useGetAllPublicNotesSubscription()
  const navDispatch = useNavDispatch()

  var notes = data?.public_notes?.filter((n) => n.chart_symbol === chartSymbol)
  notes = notes?.flat().sort((a, b) => {
    return a.time > b.time ? -1 : a.time < b.time ? 1 : a.id - b.id
  })

  const notesToday = notes?.filter((n) => {
    return new Date().setHours(0, 0, 0, 0) < new Date(n.time).getTime()
  })

  const notesYesterday = notes?.filter((n) => {
    return (
      notesToday?.indexOf(n) === -1 &&
      new Date().setHours(-24, 0, 0, 0) < new Date(n.time).getTime()
    )
  })

  const notesLastWeek = notes?.filter((n) => {
    return (
      notesToday?.indexOf(n) === -1 &&
      notesYesterday?.indexOf(n) === -1 &&
      new Date().setDate(new Date().getDate() - 7) < new Date(n.time).getTime()
    )
  })

  const notesOlder = notes?.filter((n) => {
    return (
      notesToday?.indexOf(n) === -1 &&
      notesYesterday?.indexOf(n) === -1 &&
      notesLastWeek?.indexOf(n) === -1
    )
  })

  const renderNotesListByTime = (sectionLabel: string, type: string, notes?: any[]) => {
    if (!notes) {
      return
    }
    const filteredNotes = notes.filter((a) => {
      return a.type === type
    })
    if (filteredNotes.length > 0) {
      return (
        <List>
          <ListItem>
            <Typography variant="subtitle1">{sectionLabel}</Typography>
          </ListItem>
          <Divider />
          {filteredNotes.map((note) => (
            <NoteListItem
              note={note}
              read={!!note.threads[0]?.read_at}
              channelId={note.threads[0]?.channel_id}
              key={note.id}
              onClick={(noteId) => setSelectedNoteId(noteId)}
            />
          ))}
        </List>
      )
    }
  }

  const renderNotesList = (type: string) => {
    return (
      <List className={classes.noteList}>
        <CardHeader
          disableTypography
          style={{
            textAlign: 'center',
            borderBottom: `2px solid ${theme.palette.text.secondary}`,
            fontSize: 20,
            fontWeight: 400,
            lineHeight: '24px',
            letterSpacing: 0.325,
            marginBottom: '0.5em',
          }}
          title={type.charAt(0).toUpperCase() + type.slice(1) + 's'}
        />
        <List>
          {renderNotesListByTime('Today', type, notesToday)}
          {renderNotesListByTime('Yesterday', type, notesYesterday)}
          {renderNotesListByTime('Last Week', type, notesLastWeek)}
          {renderNotesListByTime('Older', type, notesOlder)}
        </List>
      </List>
    )
  }

  useEffect(() => {
    // Skips the setting of the chart toolbar on the subpage
    navDispatch({isChartSubpage: true})
  }, [navDispatch])

  if (loading) return <Loading label />
  if (error) return <div>ERROR: {error.message}</div>

  return (
    <>
      <Typography variant="h2">Feed</Typography>
      <TwoPanes
        pane1={
          <ThreePanes
            left={renderNotesList('note')}
            middle={renderNotesList('link')}
            right={renderNotesList('file')}
            leftName={chartSymbol + 'noteList'}
            rightName={chartSymbol + 'linkList'}
          />
        }
        pane2={<>{selectedNoteId && <NoteViewer noteId={selectedNoteId} />}</>}
        name={chartSymbol + 'feedPanes'}
      />
    </>
  )
}
const useStyles = makeStyles((theme) => ({
  noteList: {
    marginRight: '10px',
    backgroundColor: theme.palette.background.paper,
  },
}))

export default ChartFeed
