import React from 'react'
import {Chart, ChartCanvas} from 'react-financial-charts'
import {ClickCallback} from 'react-financial-charts/lib/interactive'
import {XAxis, YAxis} from 'react-financial-charts/lib/axes'
import {rightDomainBasedZoomAnchor} from 'react-financial-charts/lib/utils/zoomBehavior'
import {
  CrossHairCursor,
  EdgeIndicator,
  MouseCoordinateX,
  MouseCoordinateY,
} from 'react-financial-charts/lib/coordinates'
import {timeFormat} from 'd3-time-format'
import {format} from 'd3-format'
import {LineSeries, CandlestickSeries, AreaSeries} from 'react-financial-charts/lib/series'
import {useTheme} from '@material-ui/core/styles'

import {Notes, PublicNotePreviewFragment, Note_Types_Enum} from '../../generated/types'
import {ChartData} from '.'
import LaserpointerAnnotation from './annotations/laserpointer-annotation'
import {OpenNote} from '../../utils/nav-context'
import Timeline from './annotations/timeline'
import {Label} from 'react-financial-charts/lib/annotation'
import {groupBy} from 'underscore'
import getNoteProps, {ContentType} from '../../utils/note-props'

export type ChartType = 'line' | 'candlestick' | 'area'

interface ChartProps {
  position?: number
  channelId?: string | null
  data: ChartData[]
  symbol: string
  height: number
  width: number
  timelines: ContentType[]
  notes: (Notes | PublicNotePreviewFragment)[]
  groupByFor?: string | null
  openNotes?: number[]
  xScale?: any
  xAccessor?: any
  intraday: boolean
  displayXAccessor?: any
  onNoteClick?: (openNote: OpenNote) => void
  chartType?: ChartType
  onChartClick?: (time: Date, symbol: string, value: number) => void
  onCreateContent?: (time: Date, type: Note_Types_Enum, context: ContentType) => void
}

const ChartInner = ({
  position,
  channelId,
  data,
  xScale,
  chartType = 'candlestick',
  onChartClick,
  openNotes = [],
  timelines,
  xAccessor,
  displayXAccessor,
  height,
  width,
  intraday,
  notes,
  groupByFor,
  onNoteClick,
  onCreateContent,
  symbol,
}: ChartProps) => {
  const theme = useTheme()
  const margin = {left: 0, right: 60, top: 0, bottom: 24}
  const yAccessor = (data: any) => data.close
  const high = Math.max(...data.filter((d) => !!!d.hidden).map((d) => d.high))
  const low = Math.min(...data.filter((d) => !!!d.hidden && !!d.close).map((d) => d.low))

  const buffer = (high - low) * 0.1
  const yExtents = [high + buffer, low - buffer]

  var gridHeight = height - margin.top - margin.bottom
  var gridWidth = width - margin.left - margin.right

  var yGrid = {
    innerTickSize: -1 * gridWidth,
    tickStrokeDasharray: 'Solid' as 'Solid',
    tickStrokeOpacity: 0.2,
    tickStrokeWidth: 1,
    tickStroke: theme.palette.divider,
  }
  var xGrid = {
    innerTickSize: -1 * gridHeight,
    tickStrokeDasharray: 'Solid' as 'Solid',
    tickStrokeOpacity: 0.2,
    tickStrokeWidth: 1,
    tickStroke: theme.palette.divider,
  }

  const renderSeries = () => {
    switch (chartType) {
      case 'line':
        return <LineSeries yAccessor={yAccessor} />
      case 'area':
        return <AreaSeries yAccessor={yAccessor} />
      case 'candlestick':
        return <CandlestickSeries />
    }
  }

  // Client notes are grouped by the note type (file, link, note) where as trader notes are grouped by the content type (public, private, team)
  var groupedNotes: {[x: string]: (Notes | PublicNotePreviewFragment)[]} = {}
  if (groupByFor === 'client') {
    groupedNotes['link'] = notes.filter((n) => getNoteProps(n).noteType === Note_Types_Enum.Link)
    groupedNotes['filenote'] = notes.filter(
      (n) => getNoteProps(n).noteType !== Note_Types_Enum.Link,
    )
  } else {
    groupedNotes = groupBy(notes, (n: Notes) => getNoteProps(n).contentType.toLowerCase())
  }

  // Charts are drawn right-to-left, so the start is the last datapoint
  // const start = xAccessor(data[data.length - 1])

  // End datum is the first data point that's not hidden
  var i = data.length - 1
  data.forEach((d) => {
    if (d.hidden) {
      i--
    }
  })
  const chartStart = xAccessor(data[i])

  const end = xAccessor(data[0])
  const xExtents = [chartStart + chartStart * 0.05, end]
  // difference between datapoints in ms
  const resolution = data[1].date.getTime() - data[0].date.getTime()

  const axisProps = {
    stroke: theme.brand.secondary.light,
    tickStroke: theme.brand.secondary.light,
    tickLabelFill: theme.brand.secondary.light,
  }

  return (
    <ChartCanvas
      height={height}
      ratio={window.devicePixelRatio}
      width={width}
      margin={margin}
      data={data}
      displayXAccessor={displayXAccessor}
      seriesName={symbol}
      xScale={xScale}
      xAccessor={xAccessor}
      xExtents={xExtents}
      zoomAnchor={rightDomainBasedZoomAnchor}
      type="hybrid"
    >
      <Chart id={`symbol-${position}`} yExtents={yExtents}>
        {renderSeries()}
        <Label fill={theme.palette.text.primary} fontSize={16} text={symbol} x={30} y={30} />
        <ClickCallback
          onClick={({currentItem, chartConfig, mouseXY}: any) => {
            const yValue = chartConfig.yScale.invert(mouseXY[1])
            return onChartClick && onChartClick(currentItem.date, symbol, yValue)
          }}
        />
        <MouseCoordinateX
          at="bottom"
          orient="bottom"
          displayFormat={timeFormat(intraday ? '%Y-%m-%d %H:%M' : '%Y-%m-%d')}
        />
        <MouseCoordinateY at="right" orient="right" displayFormat={format('.4s')} />
        <CrossHairCursor stroke={theme.brand.primary.dark} />
        <EdgeIndicator
          itemType="last"
          orient="right"
          edgeAt="right"
          yAccessor={(d: ChartData) => d.close}
          fill={(d: ChartData) =>
            d.close && d.open && d.close > d.open
              ? theme.palette.success.main
              : theme.palette.error.main
          }
        />
        <XAxis {...axisProps} {...xGrid} />
        <YAxis {...axisProps} {...yGrid} />
        {timelines.map((c, i) => (
          <Timeline
            index={i}
            onNoteClick={onNoteClick}
            onCreateContent={
              c === ContentType.Personal || c === ContentType.Team ? onCreateContent : undefined
            }
            contentType={c}
            key={i}
            notes={groupedNotes[c.toLowerCase()]}
            openNotes={openNotes}
          />
        ))}

        {!!channelId && <LaserpointerAnnotation resolution={resolution} channelId={channelId} />}
      </Chart>
    </ChartCanvas>
  )
}

export default ChartInner
