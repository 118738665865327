import React, {useState, ChangeEvent, useEffect, Fragment, useRef} from 'react'
import {SearchIndex} from 'algoliasearch/lite'
import {makeStyles, fade} from '@material-ui/core/styles'
import {Typography, Icon, Grid, InputBase} from '@material-ui/core'
import {format} from 'date-fns'
import Autocomplete from '@material-ui/lab/Autocomplete'
import {useHistory} from 'react-router-dom'

import {AlogliaPublicNoteType, getClientIndex} from '../../api/algolia'
import {useGetAlgoliaApiKeyQuery} from '../../generated/types'
import routes from '../../routes'

function ClientSearch() {
  const history = useHistory()
  const classes = useStyles()
  const [inputValue, setInputValue] = useState('')
  const inputRef = useRef<HTMLInputElement>(null)
  const [options, setOptions] = useState<AlogliaPublicNoteType[]>([])
  const [selectedOption, setSelectedOption] = useState<AlogliaPublicNoteType | null>(null)
  const [searchIndex, setSearchIndex] = useState<SearchIndex | null>(null)
  const {data, error} = useGetAlgoliaApiKeyQuery()
  const secureAlgoliaApiKey = data?.getAlgoliaApiKey?.apiKey
  if (error) throw error

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setInputValue(event.target.value)
  }

  const handleResultSelected = async (event: any, value: AlogliaPublicNoteType | null) => {
    setInputValue('')
    setSelectedOption(null)
    value && history.push(routes.client.note.createUrl(value.publicNoteId))
  }

  useEffect(() => {
    if (!secureAlgoliaApiKey || !!searchIndex) return
    setSearchIndex(getClientIndex(secureAlgoliaApiKey))
  }, [secureAlgoliaApiKey, searchIndex])

  useEffect(() => {
    let isActive = true
    if (inputValue === '') {
      setOptions([])
      return
    }
    searchIndex?.search(inputValue).then(({hits}: any) => {
      if (isActive) setOptions(hits)
    })
    return () => {
      isActive = false
    }
  }, [inputValue, searchIndex])

  return (
    <Autocomplete
      getOptionLabel={(option: AlogliaPublicNoteType) => option.title}
      value={selectedOption}
      classes={{
        popper: classes.suggestions,
      }}
      onChange={handleResultSelected}
      options={options}
      autoComplete
      includeInputInList
      className={classes.search}
      renderInput={(params) => (
        <Fragment>
          <Icon className={classes.searchIcon}>search</Icon>
          <InputBase
            ref={params.InputProps.ref}
            inputProps={params.inputProps}
            inputRef={inputRef}
            placeholder="Search…"
            onChange={handleChange}
            value={inputValue}
            classes={{
              root: classes.inputRoot,
              input: classes.inputInput,
              focused: classes.inputFocused,
            }}
          />
        </Fragment>
      )}
      renderOption={(option) => (
        <SearchOptionDisplay
          title={option.title}
          subtitle={option.ownerDisplayName}
          annotation={format(new Date(option.createdAt), 'MMM d')}
        />
      )}
    />
  )
}

type SODProps = {
  title: string
  subtitle: string
  annotation: string
}
const SearchOptionDisplay = ({annotation, subtitle, title}: SODProps) => (
  <Grid container alignItems="center">
    <Grid item xs>
      <Typography variant="body2" color="textPrimary">
        {title}
      </Typography>
      <Typography variant="body2" color="textSecondary">
        {subtitle}
      </Typography>
    </Grid>
    <Grid item>
      <Typography variant="body2" color="textSecondary">
        {annotation}
      </Typography>
    </Grid>
  </Grid>
)

const useStyles = makeStyles((theme) => ({
  suggestions: {
    minWidth: 450,
    marginRight: theme.spacing(1),
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    marginRight: theme.spacing(1),
    marginLeft: theme.spacing(1),
  },
  searchIcon: {
    margin: theme.spacing(0, 1),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    opacity: 0.8,
    color: theme.palette.text.primary,
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputRoot: {
    color: theme.palette.text.primary,
    width: 120,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: 300,
    }),
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    borderRadius: 30,
    // vertical padding + font size from searchIcon
    backgroundColor: fade(theme.brand.primary.main, 0.125),
    '&:hover': {
      backgroundColor: fade(theme.brand.primary.main, 0.25),
    },
    paddingLeft: `calc(1em + ${theme.spacing(3)}px)`,
    width: '100%',
    display: 'block',
  },
  inputFocused: {
    borderRadius: 4,
    backgroundColor: fade(theme.brand.primary.main, 0),
    width: 450,
  },
}))

export default ClientSearch
