import React, {useState, useEffect, useRef} from 'react'
import {isoParse} from 'd3-time-format'
import {discontinuousTimeScaleProviderBuilder} from 'react-financial-charts/lib/scale'
import useComponentSize from '@rehooks/component-size'
import ChartCanvas from './chart-canvas'
import {
  useGetFinancialDataQuery,
  QueryPeriods,
  Chart_Periods_Enum,
  Notes,
  PublicNotePreviewFragment,
  Note_Types_Enum,
} from '../../generated/types'
import {ChartData} from '.'
import {Loading} from '..'
import {OpenNote, useNavState} from '../../utils/nav-context'
import {ContentType} from '../../utils/note-props'

type ChartProps = {
  position?: number
  id?: number
  contentTypes?: ContentType[]
  period?: Chart_Periods_Enum
  type?: any
  symbol: string
  notes: (Notes | PublicNotePreviewFragment)[]
  groupByFor?: string | null
  openNotes?: number[]
  channelId?: string | null
  onNoteClick?: (openNote: OpenNote) => void
  onCreateContent?: (time: Date, type: Note_Types_Enum, context: ContentType) => void
  onChartClick?: (time: Date, symbol: string, value: number) => void
}

type ChartScale = {
  data: ChartData[]
  xScale?: any
  xAccessor?: any
  displayXAccessor?: any
}

const Chart = ({
  id,
  period = Chart_Periods_Enum.Period_1mm,
  type,
  symbol,
  contentTypes,
  position,
  openNotes,
  channelId,
  onNoteClick,
  onCreateContent,
  onChartClick,
  notes,
  groupByFor,
}: ChartProps) => {
  const [chartScale, setChartScale] = useState<ChartScale>({data: []})
  const refContainer = useRef<HTMLDivElement>(null)
  const {width, height} = useComponentSize(refContainer)
  const {contentFilter} = useNavState()

  const xScaleProvider = discontinuousTimeScaleProviderBuilder().inputDateAccessor(
    (d: ChartData) => d.date,
  )

  const {error, data: queryData} = useGetFinancialDataQuery({
    variables: {
      symbol,
      // @ts-ignore
      period: period as QueryPeriods,
    },
  })

  if (error) throw error

  const augmentData = (notes: (Notes | PublicNotePreviewFragment)[], data: ChartData[]) => {
    if (data.length === 0 || notes.length === 0) return data
    let maxDateNeeded = new Date()

    // Compute maxDateNeeded - the furthest date in the future which contains a note
    notes.forEach((n) => {
      let noteTime = new Date(n.time)
      if (noteTime > maxDateNeeded) {
        maxDateNeeded = noteTime
      }
    })

    let lastDatumOnChart = data[data.length - 1]
    // If we need to data "blank" data points between now and the note's date in the future
    if (maxDateNeeded > lastDatumOnChart.date) {
      var diff = Math.abs(maxDateNeeded.getTime() - lastDatumOnChart.date.getTime())
      var daysNeeded = Math.ceil(diff / (1000 * 3600 * 24))

      let incrementDate = new Date(lastDatumOnChart.date)

      for (let i = 0; i < daysNeeded; i++) {
        incrementDate.setDate(incrementDate.getDate() + 1)
        let dummyDatum: ChartData = {
          date: new Date(incrementDate),
          high: 0.0,
          low: 0.0,
          open: 0.0,
          close: 0.0,
          volume: 0,
          hidden: true,
        }
        data.push(dummyDatum)
      }
    }
    return data
  }

  useEffect(() => {
    const parseData = (d: any) => {
      return {
        ...d,
        date: isoParse(d.date),
      } as ChartData
    }

    const result = queryData?.getFinancialData?.data || []
    const newData = result.map(parseData)
    const augmentedData = augmentData(notes, newData)
    const {data, xScale, xAccessor, displayXAccessor} = xScaleProvider(augmentedData)

    setChartScale({data, xScale, xAccessor, displayXAccessor})
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryData])

  const timelines: ContentType[] =
    contentTypes ||
    (Object.keys(ContentType) as ContentType[]).filter((k) => contentFilter[k]).reverse()

  const renderChart = () => (
    <ChartCanvas
      position={position}
      chartType={type}
      channelId={channelId}
      notes={notes}
      groupByFor={groupByFor}
      openNotes={openNotes}
      onNoteClick={onNoteClick}
      onChartClick={onChartClick}
      onCreateContent={onCreateContent}
      timelines={timelines}
      symbol={symbol}
      intraday={
        period === Chart_Periods_Enum.Period_1mm || period === Chart_Periods_Enum.Period_5dm
      }
      {...chartScale}
      width={width}
      height={height}
    />
  )

  return (
    <div
      style={{height: '100%', flex: 1, display: 'flex', position: 'relative'}}
      ref={refContainer}
    >
      {chartScale.data.length === 0 ? <Loading /> : renderChart()}
    </div>
  )
}

export default Chart
