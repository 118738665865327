import React from 'react'
import {Route, Redirect, RouteComponentProps} from 'react-router-dom'
import {useAuth0} from './utils/auth0'
import {FullHeightLoading} from './components'
import {useUserState} from './utils/user-context'

type RouteDefinitions = {
  [key: string]: {
    [key: string]: RouteDefinition
  }
}

type RouteDefinition = {
  path: string
  createUrl: (...args: any[]) => string
}

const routes: RouteDefinitions = {
  common: {
    login: {
      path: '/login',
      createUrl: () => '/login',
    },
    fourhundredandfour: {
      path: '/fourhundredandfour',
      createUrl: () => '/fourhundredandfour',
    },
  },
  client: {
    root: {
      path: '/client',
      createUrl: () => '/client',
    },
    feed: {
      path: '/client/feed',
      createUrl: () => '/client/feed',
    },
    chart: {
      path: '/client/chart/:symbol',
      createUrl: (symbol: string) => `/client/chart/${symbol}`,
    },
    chartFeed: {
      path: '/client/chart/:symbol/feed',
      createUrl: (symbol: string) => `/client/chart/${symbol}/feed`,
    },
    chartFundamentals: {
      path: '/client/chart/:symbol/fundamentals',
      createUrl: (symbol: string) => `/client/chart/${symbol}/fundamentals`,
    },
    chartNote: {
      path: '/client/chart/:symbol/:noteId',
      createUrl: (symbol: string, noteId: number) => `/client/chart/${symbol}/${noteId}`,
    },
    note: {
      path: '/client/note/:noteId',
      createUrl: (noteId: number) => `/client/note/${noteId}`,
    },
  },
  user: {
    root: {
      path: '/user',
      createUrl: () => '/user',
    },
    noteWindow: {
      path: '/user/note/:noteId',
      createUrl: (noteId: string) => `/user/note/${noteId}`,
    },
    clients: {
      path: '/user/clients',
      createUrl: () => '/user/clients',
    },
    clientsClient: {
      path: '/user/clients/:clientId',
      createUrl: (clientId: string) => `/user/clients/${clientId}`,
    },
    clientsClientNote: {
      path: '/user/clients/:clientId/notes/:publicNoteId',
      createUrl: (clientId: string, publicNoteId: number) =>
        `/user/clients/${clientId}/notes/${publicNoteId}`,
    },
    clientsClientNoteChart: {
      path: '/user/clients/:clientId/notes/:publicNoteId/chart',
      createUrl: (clientId: string, publicNoteId: number) =>
        `/user/clients/${clientId}/notes/${publicNoteId}/chart`,
    },
    workspace: {
      path: '/user/workspace/:workspaceId',
      createUrl: (workspaceId: number) => `/user/workspace/${workspaceId}`,
    },
    workspaceFeed: {
      path: '/user/workspace/:workspaceId/feed',
      createUrl: (workspaceId: number) => `/user/workspace/${workspaceId}/feed`,
    },
    workspaceModels: {
      path: '/user/workspace/:workspaceId/models',
      createUrl: (workspaceId: number) => `/user/workspace/${workspaceId}/models`,
    },
    workspaceNews: {
      path: '/user/workspace/:workspaceId/news',
      createUrl: (workspaceId: number) => `/user/workspace/${workspaceId}/news`,
    },
  },
}

type RouteProps = {
  component: React.FC<RouteComponentProps>
  path: string
  exact?: boolean
}

export const ClientRoute = ({component: Component, path, exact}: RouteProps) => {
  const {isInitializing} = useAuth0()
  const {isFetchingUser, user} = useUserState()

  const renderContent = (props: RouteComponentProps) => {
    if (isInitializing || isFetchingUser) return <FullHeightLoading />
    if (user && !user.client_user) return <Redirect to={routes.user.root.createUrl()} />
    if (!user) {
      return (
        <Redirect to={{pathname: routes.common.login.createUrl(), state: {from: props.location}}} />
      )
    }
    return <Component {...props} />
  }

  return <Route exact={exact} path={path} render={renderContent} />
}

export const OutlineUserRoute = ({component: Component, path, exact}: RouteProps) => {
  const {isInitializing} = useAuth0()
  const {isFetchingUser, user} = useUserState()

  const renderContent = (props: RouteComponentProps) => {
    if (isInitializing || isFetchingUser) return <FullHeightLoading />
    if (user && !user.outline_user) return <Redirect to={routes.client.root.createUrl()} />
    if (!user) {
      return (
        <Redirect to={{pathname: routes.common.login.createUrl(), state: {from: props.location}}} />
      )
    }
    return <Component {...props} />
  }

  return <Route exact={exact} path={path} render={renderContent} />
}

export default routes
