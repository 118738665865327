import {createMuiTheme} from '@material-ui/core/styles'

const common = {
  shape: {
    drawerWidth: 240,
    drawerClosedWidth: 57,
    landingPaneWidth: 320,
    landingPaneClosedWidth: 36,
    toolbarHeight: 64,
  },
  overrides: {
    MuiListItemIcon: {
      root: {
        minWidth: 32,
      },
    },
  },
  typography: {
    fontFamily: [
      'Source Sans Pro',
      '-apple-system',
      'system-ui',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    h1: {
      fontSize: 64,
      fontWeight: 300,
    },
    h2: {
      fontSize: 48,
      fontWeight: 200,
    },
    h3: {
      fontSize: 38,
      fontWeight: 400,
    },
    h4: {
      fontSize: 32,
      fontWeight: 400,
    },
    h5: {
      fontSize: 24,
      fontWeight: 400,
      marginBottom: '0.5em',
    },
    h6: {
      fontSize: 20,
      fontWeight: 400,
      lineHeight: '24px',
      letterSpacing: 0.325,
      marginBottom: '0.5em',
    },
    subtitle1: {
      fontSize: 16,
      fontWeight: 400,
      lineHeight: 1.5,
      letterSpacing: '0.5px',
    },
    subtitle2: {
      fontSize: 14,
      fontWeight: 600,
    },
    body1: {
      fontSize: 16,
      fontWeight: 400,
    },
    body2: {
      fontSize: 15,
      fontWeight: 400,
    },
    button: {
      fontSize: 14,
      fontWeight: 400,
    },
    caption: {
      fontSize: 11,
      fontWeight: 400,
    },
    overline: {
      fontSize: 11,
      fontWeight: 400,
    },
    code: {
      fontFamily: `"Source Code Pro", monospace`,
      fontSize: 13,
      fontWeight: 400,
    },
  },
}

export const outlineDarkTheme = createMuiTheme({
  palette: {
    type: 'dark',
    primary: {
      main: 'hsl(223, 93%, 63%)',
      light: 'hsl(223, 83%, 73%)',
      dark: 'hsl(223, 53%, 43%)',
    },
    secondary: {
      main: 'hsl(353, 93%, 63%)',
      light: 'hsl(353, 83%, 73%)',
      dark: 'hsl(353, 53%, 43%)',
    },
    text: {
      primary: 'hsl(223, 0%, 100%)',
    },
    background: {
      default: 'hsl(223, 29%, 23%)',
      paper: 'hsl(223, 24%, 17%)',
    },
    action: {
      selected: 'hsl(223, 35%, 33%)',
    },
  },
  brand: {
    type: 'dark',
    primary: {
      main: 'hsl(223, 20%, 37%)',
      light: 'hsl(223, 67%, 57%)',
      dark: 'hsl(223, 79%, 32%)',
    },
    secondary: {
      main: 'hsl(223, 13%, 52%)',
      light: 'hsl(223, 13%, 61%)',
      dark: 'hsl(223, 13%, 36%)',
    },
    context: {
      personal: 'hsl(28, 90%, 51%)',
      team: 'hsl(44, 90%, 51%)',
      client: 'hsl(162, 66%, 45%)',
      link: 'hsl(207, 85%, 58%)',
      filenote: 'hsl(188, 66%, 45%)',
    },
    active: {
      main: 'hsl(247, 90%, 61%)',
      logo: 'hsl(43, 63%, 63%)',
    },
    info: {
      highlight: 'hsl(202, 22%, 27%)',
      textcolor: 'hsl(203, 53%, 56%)',
    },
    success: {
      highlight: 'hsl(177, 18%, 25%)',
      textcolor: 'hsl(169, 38%, 49%)',
    },
    warning: {
      highlight: 'hsl(31, 21%, 29%)',
      textcolor: 'hsl(30, 100%, 63%)',
    },
    error: {
      highlight: 'hsl(360, 16%, 30%)',
      textcolor: 'hsl(4, 100%, 71%)',
    },
  },
  ...common,
})

export const outlineLightTheme = createMuiTheme({
  palette: {
    type: 'light',
    primary: {
      main: 'hsl(223, 93%, 63%)',
      light: 'hsl(223, 83%, 73%)',
      dark: 'hsl(223, 53%, 43%)',
    },
    secondary: {
      main: 'hsl(3, 93%, 63%)',
      light: 'hsl(3, 93%, 73%)',
      dark: 'hsl(3, 73%, 53%)',
    },
    text: {
      primary: 'hsl(0, 0%, 13%)',
    },
    action: {
      selected: 'hsl(223, 35%, 95%)',
    },
  },
  brand: {
    type: 'light',
    primary: {
      main: 'hsl(223, 13%, 63%)',
      light: 'hsl(223, 13%, 73%)',
      dark: 'hsl(223, 13%, 43%)',
    },
    secondary: {
      main: 'hsl(223, 13%, 53%)',
      light: 'hsl(223, 13%, 63%)',
      dark: 'hsl(223, 13%, 43%)',
    },
    context: {
      personal: 'hsl(22, 100%, 67%)',
      team: 'hsl(44, 100%, 67%)',
      client: 'hsl(162, 66%, 45%)',
      link: 'hsl(207, 85%, 58%)',
      filenote: 'hsl(188, 66%, 45%)',
    },
    active: {
      main: 'hsl(247, 93%, 63%)',
      logo: 'hsl(43, 63%, 63%)',
    },
    info: {
      highlight: 'hsl(198, 42%, 91%)',
      textcolor: 'hsl(198, 87%, 32%)',
    },
    success: {
      highlight: 'hsl(169, 31%, 90%)',
      textcolor: 'hsl(172, 78%, 27%)',
    },
    warning: {
      highlight: 'hsl(29, 74%, 92%)',
      textcolor: 'hsl(30, 89%, 45%)',
    },
    error: {
      highlight: 'hsl(360, 74%, 94%)',
      textcolor: 'hsl(360, 72%, 56%)',
    },
    button: {
      // background: 'hsl(233, 54%, 46%)',
      background: 'hsl(247, 93%, 63%)',
      hoverBackground: 'hsl(247, 93%, 80%)',
      text: 'hsl(233, 0%, 100%)',
    },
  },
  ...common,
})
