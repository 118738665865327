import React from 'react'
import clsx from 'clsx'
import {makeStyles, fade, Theme, useTheme} from '@material-ui/core/styles'
import {Button, ButtonGroup} from '@material-ui/core'
import {ContentType, CONTENT_ICONS} from '../../utils/note-props'
import {useNavState, useNavDispatch} from '../../utils/nav-context'

type FBProps = {
  contentType: ContentType
  onToggle: (type: ContentType) => any
  selected: boolean
}

function FilterButton({contentType, onToggle, selected, ...props}: FBProps) {
  const Icon = CONTENT_ICONS[contentType]
  const theme = useTheme()
  const color = theme.brand.context[contentType.toLowerCase()]
  const classes = useStyles({color})

  return (
    <Button
      classes={{
        root: clsx(
          classes.button,
          {[classes.active]: selected},
          {[classes.personalButton]: contentType === ContentType.Personal},
        ),
      }}
      onClick={() => onToggle(contentType)}
      title={contentType}
      {...props}
    >
      <Icon />
      <span
        className={clsx(
          {[classes.activeLabel]: selected},
          {[classes.personal]: contentType === ContentType.Personal},
          {[classes.impersonal]: contentType !== ContentType.Personal},
        )}
      >
        {contentType}
      </span>
    </Button>
  )
}

function ContentFilterToolbar() {
  const classes = useStyles({color: '#fff'})
  const {contentFilter: active} = useNavState()
  const dispatch = useNavDispatch()

  const onToggle = (type: ContentType) => {
    dispatch({toggleContentFilter: type})
  }

  return (
    <ButtonGroup className={classes.periodSelect}>
      <FilterButton
        contentType={ContentType.Personal}
        selected={active[ContentType.Personal]}
        onToggle={onToggle}
      />
      <FilterButton
        contentType={ContentType.Team}
        selected={active[ContentType.Team]}
        onToggle={onToggle}
      />
      <FilterButton
        contentType={ContentType.Client}
        selected={active[ContentType.Client]}
        onToggle={onToggle}
      />
    </ButtonGroup>
  )
}

const useStyles = makeStyles<Theme, {color: string}>((theme) => ({
  periodSelect: {
    margin: theme.spacing(0, 1),
  },
  button: {
    backgroundColor: theme.palette.background.paper,
    border: `1px solid ${theme.palette.text.primary}`,
    borderColor: fade(theme.palette.text.primary, 0.3),
    color: fade(theme.palette.text.primary, 0.5),
    fontSize: 14,
    fontWeight: 'normal',
    lineHeight: '16px',
    padding: '8px 8px 8px 4px',
    textTransform: 'none',
    '&:hover': {
      color: (props) => fade(props.color, 0.7),
    },
  },
  active: {
    color: (props) => props.color,
  },
  activeLabel: {
    color: theme.palette.text.primary,
    fontWeight: 600,
  },
  selectType: {
    backgroundColor: theme.palette.background.paper,
    padding: '8px 0px 5px 8px',
  },
  personal: {
    marginLeft: '-2px',
  },
  impersonal: {
    paddingLeft: '4px',
  },
  personalButton: {
    paddingLeft: '2px',
  },
}))

export default ContentFilterToolbar
